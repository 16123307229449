<template>
    <AppLayout>
        <template #navigation>
            <div class="create-view__aside">
                <span class="create-view__group">Choice based</span>
                <DraggableComponent class="create-view__questions">
                    <div class="create-view__question create-view__question--disc">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="none"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <circle cx="12" cy="12" r="10" stroke="#bdbdbd" />
                                <circle cx="12" cy="12" r="4" fill="#1abc9c" />
                            </svg>
                            <span>Single selection</span>
                        </ButtonComponent>
                    </div>
                    <div class="create-view__question create-view__question--check-square">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#bdbdbd"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path stroke="#1abc9c" stroke-width="2" d="m9 11 3 3L22 4" />
                                <path
                                    d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
                                />
                            </svg>
                            <span>Multiple selection</span>
                        </ButtonComponent>
                    </div>
                    <div class="create-view__question create-view__question--square-chevron">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#bdbdbd"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M11 5H21C21.5523 5 22 5.44772 22 6V18C22 18.5523 21.5523 19 21 19H11V5Z"
                                    fill="#eaeaea"
                                    stroke="none"
                                />
                                <path
                                    d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z"
                                    stroke="#bdbdbd"
                                />
                                <path d="M14 11L16.5 13.5L19 11" stroke="#34495e" />
                            </svg>
                            <span>Dropdown</span>
                        </ButtonComponent>
                    </div>
                    <div class="create-view__question create-view__question--grid">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#bdbdbd"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M2 6C2 8.20914 3.79086 10 6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6Z"
                                />
                                <path
                                    d="M14 6C14 8.20914 15.7909 10 18 10C20.2091 10 22 8.20914 22 6C22 3.79086 20.2091 2 18 2C15.7909 2 14 3.79086 14 6Z"
                                />
                                <path
                                    d="M14 18C14 20.2091 15.7909 22 18 22C20.2091 22 22 20.2091 22 18C22 15.7909 20.2091 14 18 14C15.7909 14 14 15.7909 14 18Z"
                                />
                                <path
                                    d="M2 18C2 20.2091 3.79086 22 6 22C8.20914 22 10 20.2091 10 18C10 15.7909 8.20914 14 6 14C3.79086 14 2 15.7909 2 18Z"
                                />
                                <circle cx="6" cy="6" r="2" fill="#1abc9c" stroke="none" />
                                <circle cx="18" cy="18" r="2" fill="#1abc9c" stroke="none" />
                            </svg>
                            <span>Matrix</span></ButtonComponent
                        >
                    </div>
                    <div class="create-view__question create-view__question--ruler">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="#6a6aeb"
                                stroke="#6a6aeb"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z"
                                />
                                <path d="M8 20V15M16 20V15" stroke="#95a5a6" stroke-width="2" />
                                <path d="M4 20V12" stroke="#eaeaea" stroke-width="2" />
                                <path d="M12 20V12" stroke="#eaeaea" stroke-width="2" />
                                <path d="M20 20V12" stroke="#eaeaea" stroke-width="2" />
                            </svg>
                            <span>Opinion scale</span>
                        </ButtonComponent>
                    </div>
                    <div class="create-view__question create-view__question--dashboard">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#eaeaea"
                                stroke-width="3"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                    stroke="#eaeaea"
                                />
                                <path
                                    d="M5.38544 19.5C3.30939 17.6676 2 14.9867 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 14.9867 20.6906 17.6676 18.6146 19.5"
                                    stroke="#2ecc71"
                                />
                                <path
                                    d="M5.38544 19.5C3.30939 17.6676 2 14.9867 2 12C2 6.47715 6.47715 2 12 2C16.2915 2 19.9517 4.70333 21.3704 8.5"
                                    stroke="#ffba59"
                                />
                                <path
                                    d="M12 2C6.47715 2 2 6.47715 2 12C2 14.9867 3.30939 17.6676 5.38544 19.5"
                                    stroke="#e74c3c"
                                />
                                <g>
                                    <path
                                        d="M14.2369 12.8405L17.056 13.8665"
                                        stroke="#34495e"
                                        stroke-width="1.5"
                                    />
                                    <path
                                        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
                                        stroke="#34495e"
                                        stroke-width="1.5"
                                    />
                                </g>
                            </svg>
                            <span>NPS®</span>
                        </ButtonComponent>
                    </div>
                    <div class="create-view__question create-view__question--star">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="#ffd670"
                                stroke="#ffd670"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="m12 2 3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"
                                />
                            </svg>
                            <span>Rating</span>
                        </ButtonComponent>
                    </div>
                    <div class="create-view__question create-view__question--slider">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#bdbdbd"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <rect x="1" y="5" width="22" height="14" rx="7" ry="7" />
                                <circle cx="16" cy="12" r="3" fill="#3498db" stroke="#3498db" />
                            </svg>
                            <span>Sliders</span>
                        </ButtonComponent>
                    </div>
                    <div class="create-view__question create-view__question--emoji">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="#ffd670"
                                stroke="#ffd670"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                />
                                <path d="M8 10.5H8.01" stroke="#34495e" stroke-width="3" />
                                <path d="M16 10.5H16.01" stroke="#34495e" stroke-width="3" />
                                <path d="M5 13.5H5.01" stroke="#ffba59" stroke-width="2.5" />
                                <path d="M19 13.5H19.01" stroke="#ffba59" stroke-width="2.5" />
                                <path
                                    d="M8.86775 13.529C8.94548 13.2181 9.22481 13 9.54527 13H14.4547C14.7752 13 15.0545 13.2181 15.1322 13.529V13.529C15.573 15.2921 14.2395 17 12.4222 17H11.5778C9.76047 17 8.42698 15.2921 8.86775 13.529V13.529Z"
                                    fill="#34495e"
                                />
                            </svg>

                            <span>Emojis</span>
                        </ButtonComponent>
                    </div>
                    <div class="create-view__question create-view__question--thumb">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#7f8c8d"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path d="M14 9V5a3 3 0 0 0-3-3l-4 9" />
                                <path
                                    d="M7 11v11H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3ZM14 9h5.66a2 2 0 0 1 2 2.3l-1.38 9a2 2 0 0 1-2 1.7H7V11"
                                    fill="#fff"
                                />
                                <path d="M7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" fill="#2ecc71" />
                            </svg>
                            <span>Thumbs</span>
                        </ButtonComponent>
                    </div>
                </DraggableComponent>
                <span class="create-view__group">Media based</span>
                <ul class="create-view__questions">
                    <li class="create-view__question create-view__question--image">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#34495e"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                                    fill="#59a8ff"
                                />
                                <path
                                    d="M9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.89543 7 7 7.89543 7 9C7 10.1046 7.89543 11 9 11Z"
                                    fill="#ffd670"
                                    stroke="#ffd670"
                                />
                                <path
                                    d="M5 20.25L11.3603 14.4206C13.9666 12.0317 18.0524 12.3554 20.25 15.125V19.25C20.25 19.8023 19.8023 20.25 19.25 20.25H5Z"
                                    fill="#66ddaa"
                                    stroke="none"
                                />
                            </svg>
                            <span>Picture selection</span>
                        </ButtonComponent>
                    </li>
                    <li class="create-view__question create-view__question--rank">
                        <ButtonComponent
                            @mouseenter="handleRankEnter"
                            @mouseleave="handleRankLeave"
                        >
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <g>
                                    <path
                                        d="M16 11H20C21.1046 11 22 11.8954 22 13V19C22 20.1046 21.1046 21 20 21H16V11Z"
                                        fill="#ffcc00"
                                    />
                                    <path
                                        d="M18 14H19C19.5523 14 20 14.4477 20 15V16.5M20 16.5V18C20 18.5523 19.5523 19 19 19H18M20 16.5H18.5"
                                        stroke="white"
                                    />
                                </g>
                                <g>
                                    <path
                                        d="M2 9C2 7.89543 2.89543 7 4 7H8V21H4C2.89543 21 2 20.1046 2 19V9Z"
                                        fill="#ff9900"
                                    />
                                    <path
                                        d="M4 14H5C5.55228 14 6 14.4477 6 15V15.5974C6 16.1497 5.55228 16.5974 5 16.5974V16.5974C4.44772 16.5974 4 17.0451 4 17.5974V18C4 18.5523 4.44772 19 5 19H6"
                                        stroke="white"
                                    />
                                </g>
                                <g>
                                    <path
                                        d="M8 5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V21H8V5Z"
                                        fill="#f56762"
                                    />
                                    <path
                                        d="M12.5 19V12.6036C12.5 12.3808 12.2307 12.2693 12.0732 12.4268L10.5 14"
                                        stroke="white"
                                    />
                                </g>
                            </svg>
                            <div ref="confetti" class="create-view__question--rank__confetti" />
                            <span>Picture ranking</span>
                        </ButtonComponent>
                    </li>
                    <li class="create-view__question create-view__question--upload-cloud">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#34495e"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"
                                    stroke="#3498db"
                                />
                                <g>
                                    <path d="m16 16-4-4-4 4M12 12v9" />
                                    <path d="m16 16-4-4-4 4" />
                                </g>
                            </svg>
                            <span>File upload</span>
                        </ButtonComponent>
                    </li>
                </ul>
                <span class="create-view__group">Text based</span>
                <ul class="create-view__questions">
                    <li class="create-view__question create-view__question--text">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#bdbdbd"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <rect x="1" y="8" width="22" height="8" rx="2" />
                                <path d="M17 12H5" stroke="#16a085" />
                            </svg>
                            <span>Text box</span>
                        </ButtonComponent>
                    </li>
                    <li class="create-view__question create-view__question--comment">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#bdbdbd"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z"
                                />
                                <path d="M19 8H5" stroke="#16a085" />
                                <path d="M16 12H5" stroke="#16a085" />
                                <path d="M16 16H5" stroke="#16a085" />
                            </svg>
                            <span>Comment box</span>
                        </ButtonComponent>
                    </li>
                    <li class="create-view__question create-view__question--multiple-text">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#bdbdbd"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <rect x="1" y="2" width="22" height="8" rx="2" />
                                <rect x="1" y="14" width="22" height="8" rx="2" />
                                <path d="M13 6H5" stroke="#16a085" />
                                <path d="M17 18H5" stroke="#ff9900" />
                            </svg>
                            <span>Multiple text box</span>
                        </ButtonComponent>
                    </li>
                    <li class="create-view__question create-view__question--profile">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#bdbdbd"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z"
                                />
                                <path
                                    d="M5 15.7778C5 14.7959 5.79594 14 6.77778 14H10.2222C11.2041 14 12 14.7959 12 15.7778V15.7778C12 15.9005 11.9005 16 11.7778 16H5.22222C5.09949 16 5 15.9005 5 15.7778V15.7778Z"
                                    fill="#34495e"
                                    stroke="#34495e"
                                />
                                <path
                                    d="M8.49999 11.5417C9.51251 11.5417 10.3333 10.7209 10.3333 9.70833C10.3333 8.69581 9.51251 7.875 8.49999 7.875C7.48747 7.875 6.66666 8.69581 6.66666 9.70833C6.66666 10.7209 7.48747 11.5417 8.49999 11.5417Z"
                                    fill="#34495e"
                                    stroke="#34495e"
                                />
                                <path d="M17.5 8.25H16" stroke="#16a085" />
                                <path d="M19 12H16" stroke="#16a085" />
                                <path d="M19 15.75H16" stroke="#16a085" />
                            </svg>
                            <span>Name</span>
                        </ButtonComponent>
                    </li>
                    <li class="create-view__question create-view__question--hash">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#16a085"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <line x1="4" y1="9" x2="20" y2="9" />
                                <line x1="4" y1="15" x2="20" y2="15" />
                                <line x1="10" y1="3" x2="8" y2="21" />
                                <line x1="16" y1="3" x2="14" y2="21" />
                            </svg>
                            <span>Number</span>
                        </ButtonComponent>
                    </li>
                    <li class="create-view__question create-view__question--at-sign">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#16a085"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <circle cx="12" cy="12" r="4" />
                                <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94" />
                            </svg>
                            <span>Email</span>
                        </ButtonComponent>
                    </li>
                </ul>
                <span class="create-view__group">Other</span>
                <ul class="create-view__questions">
                    <li class="create-view__question create-view__question--page-break">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="#eaeaea"
                                stroke="#eaeaea"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <rect x="4" y="-12" width="16" height="20" />
                                <rect x="4" y="16" width="16" height="20" />
                                <g>
                                    <path d="M16 0H8" stroke="#34495e" />
                                    <path d="M16 4H8" stroke="#34495e" />
                                </g>
                                <g>
                                    <path d="M16 24H8" stroke="#34495e" />
                                    <path d="M16 20H8" stroke="#34495e" />
                                </g>
                                <path
                                    d="M4 12L1 13.7321L1 10.2679L4 12Z"
                                    fill="#59a8ff"
                                    stroke="#59a8ff"
                                />
                                <path
                                    d="M20 12L23 13.7321V10.2679L20 12Z"
                                    fill="#59a8ff"
                                    stroke="#59a8ff"
                                />
                            </svg>
                            <span>Page break</span>
                        </ButtonComponent>
                    </li>
                    <li class="create-view__question create-view__question--bookmark">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="url(#section_gradient)"
                                stroke="url(#section_gradient)"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <defs>
                                    <linearGradient id="section_gradient">
                                        <stop offset="0%" stop-color="#3498db" />
                                        <stop offset="50%" stop-color="#94dbfc" />
                                        <stop offset="100%" stop-color="#9be9c8" />
                                    </linearGradient>
                                </defs>
                                <path d="m19 21-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
                            </svg>
                            <span>Section break</span>
                        </ButtonComponent>
                    </li>
                    <li class="create-view__question create-view__question--message-circle">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="none"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M3.00003 11.5C2.99659 12.8199 3.30496 14.1219 3.90003 15.3C4.6056 16.7118 5.69028 17.8992 7.03258 18.7293C8.37488 19.5594 9.92179 19.9994 11.5 20C12.8199 20.0035 14.1219 19.6951 15.3 19.1L21 21L19.1 15.3C19.6951 14.1219 20.0035 12.8199 20 11.5C19.9994 9.92179 19.5594 8.37488 18.7293 7.03258C17.8992 5.69028 16.7118 4.6056 15.3 3.90003C14.1219 3.30496 12.8199 2.99659 11.5 3.00003H11C8.91568 3.11502 6.94699 3.99479 5.47089 5.47089C3.99479 6.94699 3.11502 8.91568 3.00003 11V11.5Z"
                                    fill="#007aff"
                                    stroke="#007aff"
                                />
                                <path
                                    d="M11.5652 8.60798H12.5812V14H11.5652V11.736H9.34925V14H8.34125V8.60798H9.34925V10.864H11.5652V8.60798Z"
                                    fill="white"
                                />
                                <path
                                    d="M13.7006 14V9.94398H14.6606V14H13.7006ZM13.6046 8.72798C13.6046 8.56798 13.6606 8.43198 13.7726 8.31998C13.8846 8.20798 14.0206 8.15198 14.1806 8.15198C14.3406 8.15198 14.4766 8.20798 14.5886 8.31998C14.7006 8.43198 14.7566 8.56798 14.7566 8.72798C14.7566 8.89331 14.7006 9.03198 14.5886 9.14398C14.4766 9.25598 14.3406 9.31198 14.1806 9.31198C14.0206 9.31198 13.8846 9.25598 13.7726 9.14398C13.6606 9.03198 13.6046 8.89331 13.6046 8.72798Z"
                                    fill="white"
                                />
                            </svg>
                            <span>Welcome page</span>
                        </ButtonComponent>
                    </li>
                    <li
                        class="create-view__question create-view__question--message-circle-reversed"
                    >
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke="none"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z"
                                    fill="#2ecc71"
                                    stroke="#2ecc71"
                                />
                                <path
                                    d="M7.51722 13.176H8.38122C9.02655 13.176 9.34922 12.9307 9.34922 12.44C9.34922 11.9387 9.01322 11.688 8.34122 11.688H7.51722V13.176ZM7.51722 9.43203V10.88H8.12522C8.48788 10.88 8.75988 10.808 8.94122 10.664C9.12255 10.52 9.21322 10.336 9.21322 10.112C9.21322 9.90403 9.13588 9.7387 8.98122 9.61603C8.83188 9.49337 8.60255 9.43203 8.29322 9.43203H7.51722ZM6.50922 8.60803H8.42122C8.99722 8.60803 9.44522 8.74403 9.76522 9.01603C10.0852 9.28803 10.2452 9.64003 10.2452 10.072C10.2452 10.3654 10.1652 10.6214 10.0052 10.84C9.84522 11.0534 9.63988 11.1947 9.38922 11.264C9.67722 11.3334 9.91455 11.4747 10.1012 11.688C10.2879 11.896 10.3812 12.1814 10.3812 12.544C10.3812 12.9707 10.2319 13.32 9.93322 13.592C9.63455 13.864 9.22122 14 8.69322 14H6.50922V8.60803Z"
                                    fill="white"
                                />
                                <path
                                    d="M12.3316 13.92L10.7316 9.94403H11.7956L12.2676 11.24C12.4116 11.624 12.5263 11.9467 12.6116 12.208C12.7023 12.4694 12.7583 12.6347 12.7796 12.704L12.8036 12.816C12.8569 12.6294 13.0276 12.104 13.3156 11.24L13.7396 9.94403H14.7476L13.2836 13.888C12.8783 14.9707 12.2596 15.512 11.4276 15.512C11.2729 15.512 11.1396 15.4987 11.0276 15.472V14.68C11.1129 14.7014 11.2036 14.712 11.2996 14.712C11.7849 14.712 12.1289 14.448 12.3316 13.92Z"
                                    fill="white"
                                />
                                <path
                                    d="M17.7764 11.64V11.52C17.7657 11.2587 17.6937 11.0507 17.5604 10.896C17.4324 10.736 17.2431 10.656 16.9924 10.656C16.7631 10.656 16.5631 10.7414 16.3924 10.912C16.2271 11.0774 16.1204 11.32 16.0724 11.64H17.7764ZM18.4324 12.944V13.776C18.1497 13.952 17.7417 14.04 17.2084 14.04C16.5631 14.04 16.0537 13.848 15.6804 13.464C15.3071 13.08 15.1204 12.584 15.1204 11.976C15.1204 11.3307 15.2964 10.824 15.6484 10.456C16.0004 10.088 16.4404 9.90403 16.9684 9.90403C17.4964 9.90403 17.9151 10.064 18.2244 10.384C18.5337 10.704 18.6884 11.1707 18.6884 11.784C18.6884 11.9334 18.6697 12.1254 18.6324 12.36H16.0884C16.1577 12.6534 16.3017 12.8774 16.5204 13.032C16.7444 13.1867 17.0217 13.264 17.3524 13.264C17.7791 13.264 18.1391 13.1574 18.4324 12.944Z"
                                    fill="white"
                                />
                            </svg>
                            <span>Thank you page</span>
                        </ButtonComponent>
                    </li>
                    <li class="create-view__question create-view__question--badge-check">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    d="M15.4448 12.9434L15.1709 15.5H8.7041V15.3013H8.9458C9.15706 15.3013 9.32715 15.2637 9.45605 15.1885C9.54915 15.1383 9.62077 15.0524 9.6709 14.9307C9.71029 14.8447 9.72998 14.6191 9.72998 14.2539V9.46289C9.72998 9.09049 9.71029 8.85775 9.6709 8.76465C9.63151 8.67155 9.55094 8.59098 9.4292 8.52295C9.31104 8.45133 9.1499 8.41553 8.9458 8.41553H8.7041V8.2168H12.5874V8.41553H12.2705C12.0592 8.41553 11.8892 8.45312 11.7603 8.52832C11.6672 8.57845 11.5938 8.66439 11.54 8.78613C11.5007 8.87207 11.481 9.09766 11.481 9.46289V14.1035C11.481 14.4759 11.5024 14.714 11.5454 14.8179C11.5884 14.9181 11.6725 14.9933 11.7979 15.0435C11.8874 15.0757 12.104 15.0918 12.4478 15.0918H13.0547C13.4414 15.0918 13.7637 15.0238 14.0215 14.8877C14.2793 14.7516 14.5013 14.5368 14.6875 14.2432C14.8773 13.9495 15.0563 13.5163 15.2246 12.9434H15.4448Z"
                                    fill="#59a8ff"
                                />
                                <path
                                    d="M9.51417 2.91592C8.90059 3.43874 8.13773 3.75471 7.33417 3.81884C6.42593 3.8911 5.57317 4.28467 4.92892 4.92892C4.28467 5.57317 3.8911 6.42593 3.81884 7.33417C3.75471 8.13773 3.43874 8.90059 2.91592 9.51417C2.32473 10.2075 2 11.0888 2 12C2 12.9112 2.32473 13.7925 2.91592 14.4858C3.43874 15.0994 3.75471 15.8623 3.81884 16.6658C3.8911 17.5741 4.28467 18.4268 4.92892 19.0711C5.57317 19.7153 6.42593 20.1089 7.33417 20.1812C8.13773 20.2453 8.90059 20.5613 9.51417 21.0841C10.2075 21.6753 11.0888 22 12 22C12.9112 22 13.7925 21.6753 14.4858 21.0841C15.0994 20.5613 15.8623 20.2453 16.6658 20.1812C17.5741 20.1089 18.4268 19.7153 19.0711 19.0711C19.7153 18.4268 20.1089 17.5741 20.1812 16.6658C20.2453 15.8623 20.5613 15.0994 21.0841 14.4858C21.6753 13.7925 22 12.9112 22 12C22 11.0888 21.6753 10.2075 21.0841 9.51417C20.5613 8.90059 20.2453 8.13773 20.1812 7.33417C20.1089 6.42593 19.7153 5.57317 19.0711 4.92892C18.4268 4.28467 17.5741 3.8911 16.6658 3.81884C15.8623 3.75471 15.0994 3.43874 14.4858 2.91592C13.7925 2.32473 12.9112 2 12 2C11.0888 2 10.2075 2.32473 9.51417 2.91592Z"
                                    stroke="#ffcc00"
                                />
                            </svg>
                            <span>Logo</span>
                        </ButtonComponent>
                    </li>
                    <li class="create-view__question create-view__question--tag">
                        <ButtonComponent>
                            <svg
                                width="22"
                                height="22"
                                fill="#34495e"
                                stroke="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M13.42 20.58L20.59 13.41C20.9625 13.0353 21.1716 12.5284 21.1716 12C21.1716 11.4716 20.9625 10.9647 20.59 10.59L12 2H2V12L10.59 20.58C10.7757 20.766 10.9963 20.9135 11.2391 21.0141C11.4819 21.1148 11.7422 21.1666 12.005 21.1666C12.2678 21.1666 12.5281 21.1148 12.7709 21.0141C13.0137 20.9135 13.2343 20.766 13.42 20.58ZM7 10C8.65685 10 10 8.65685 10 7C10 5.34315 8.65685 4 7 4C5.34315 4 4 5.34315 4 7C4 8.65685 5.34315 10 7 10Z"
                                />
                                <path
                                    d="M20.59 13.41L21.2971 14.1171L21.2992 14.115L20.59 13.41ZM13.42 20.58L12.7129 19.8729L12.7125 19.8733L13.42 20.58ZM20.59 10.59L21.2992 9.885L21.2971 9.88289L20.59 10.59ZM12 2L12.7071 1.29289C12.5196 1.10536 12.2652 1 12 1V2ZM2 2V1C1.44772 1 1 1.44772 1 2H2ZM2 12H1C1 12.2654 1.10552 12.5199 1.29331 12.7075L2 12ZM10.59 20.58L11.2975 19.8733L11.2967 19.8725L10.59 20.58ZM19.8829 12.7029L12.7129 19.8729L14.1271 21.2871L21.2971 14.1171L19.8829 12.7029ZM20.1716 12C20.1716 12.2642 20.067 12.5176 19.8808 12.705L21.2992 14.115C21.858 13.5529 22.1716 12.7926 22.1716 12H20.1716ZM19.8808 11.295C20.067 11.4824 20.1716 11.7358 20.1716 12H22.1716C22.1716 11.2074 21.858 10.4471 21.2992 9.885L19.8808 11.295ZM11.2929 2.70711L19.8829 11.2971L21.2971 9.88289L12.7071 1.29289L11.2929 2.70711ZM2 3H12V1H2V3ZM3 12V2H1V12H3ZM11.2967 19.8725L2.70669 11.2925L1.29331 12.7075L9.88331 21.2875L11.2967 19.8725ZM11.6221 20.0903C11.5007 20.04 11.3904 19.9663 11.2975 19.8733L9.8825 21.2867C10.1611 21.5656 10.492 21.7869 10.8562 21.9379L11.6221 20.0903ZM12.005 20.1666C11.8736 20.1666 11.7435 20.1407 11.6221 20.0903L10.8562 21.9379C11.2204 22.0889 11.6108 22.1666 12.005 22.1666V20.1666ZM12.3879 20.0903C12.2665 20.1407 12.1364 20.1666 12.005 20.1666V22.1666C12.3992 22.1666 12.7896 22.0889 13.1538 21.9379L12.3879 20.0903ZM12.7125 19.8733C12.6196 19.9663 12.5093 20.04 12.3879 20.0903L13.1538 21.9379C13.518 21.7869 13.8489 21.5656 14.1275 21.2867L12.7125 19.8733ZM9 7C9 8.10457 8.10457 9 7 9V11C9.20914 11 11 9.20914 11 7H9ZM7 5C8.10457 5 9 5.89543 9 7H11C11 4.79086 9.20914 3 7 3V5ZM5 7C5 5.89543 5.89543 5 7 5V3C4.79086 3 3 4.79086 3 7H5ZM7 9C5.89543 9 5 8.10457 5 7H3C3 9.20914 4.79086 11 7 11V9Z"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M6.77782 18.1879C6.48734 19.0189 6.15693 19.7441 5.8109 20.3302C5.4535 20.9356 5.10736 21.3449 4.81353 21.5782C4.51848 21.8124 4.37485 21.7974 4.34971 21.793C4.32456 21.7885 4.18448 21.7535 3.98732 21.4325C3.79098 21.1128 3.60572 20.6098 3.47693 19.9187C3.22079 18.5442 3.22733 16.6408 3.59563 14.552C3.96393 12.4633 4.6088 10.6724 5.31959 9.46842C5.67699 8.86303 6.02313 8.45369 6.31696 8.22047C6.61201 7.98629 6.75564 8.00123 6.78078 8.00567C6.80593 8.0101 6.94601 8.04518 7.14317 8.36615C7.23785 8.5203 7.32995 8.71709 7.41439 8.95706C8.16401 8.79911 8.75894 8.22135 8.94174 7.48121C8.9112 7.42607 8.87974 7.3721 8.84735 7.31937C8.47817 6.71835 7.91877 6.17547 7.12808 6.03605C6.33738 5.89663 5.62604 6.21544 5.07357 6.65395C4.51989 7.09342 4.02596 7.7256 3.59732 8.45166C2.73604 9.91057 2.02494 11.9423 1.62601 14.2047C1.22709 16.4671 1.20041 18.6196 1.51077 20.2851C1.66524 21.1139 1.91316 21.8769 2.28314 22.4793C2.65232 23.0803 3.21172 23.6232 4.00241 23.7626C4.79311 23.902 5.50445 23.5832 6.05692 23.1447C6.6106 22.7052 7.10453 22.073 7.53317 21.347C7.8159 20.8681 8.08245 20.3274 8.32708 19.7372L6.77782 18.1879Z"
                                    fill="#eaeaea"
                                />
                            </svg>
                            <span>White label</span>
                        </ButtonComponent>
                    </li>
                </ul>
            </div>
            <div class="create-view__footer">
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <HelpComponent
                            width="331px"
                            placement="top-start"
                            markdown="logic"
                            external-link="https://www.surveylegend.com/user-guide/survey-logic/"
                        />
                        <i class="create-view__icon create-view__icon--logic"></i>
                        <span>Logic & branching</span>
                    </RowComponent>
                    <ToggleComponent
                        v-model="survey.logicEnabled"
                        prevent
                        @change="handleChange($event, 'logicEnabled')"
                    />
                </RowComponent>
            </div>
        </template>
        <div class="create-view">
            <div class="create-view__preview">
                <TooltipComponent width="auto" placement="left">
                    <template #tooltip>
                        <span>Preview</span>
                    </template>
                    <ButtonComponent
                        type="info"
                        @mouseenter="visiblePreviewPopover = true"
                        @mouseleave="visiblePreviewPopover = false"
                        @click="handlePreview"
                        ><svg width="16" height="16" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                            <path
                                fill-rule="evenodd"
                                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </ButtonComponent>
                </TooltipComponent>
            </div>
            <div class="create-view__participate">
                <DropzoneComponent />
            </div>
        </div>
        <template #controls> <div class="create-view__aside"></div></template>
    </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'

import { db } from '@/firebase'
import { mapGetters } from 'vuex'

const surveys = db.ref('v2/survey_meta/')

export default {
    name: 'Create',

    metaInfo: {
        title: 'Create'
    },

    components: {
        AppLayout
    },

    data() {
        return {
            survey: {
                logicEnabled: false
            },
            surveyItems: [],
            visiblePreviewPopover: false,
            visibleRightAside: false
        }
    },

    computed: {
        ...mapGetters({
            permissions: 'auth/permissions'
        })
    },

    watch: {
        '$route.params.id': {
            immediate: true,
            handler(id) {
                this.$rtdbBind('survey', surveys.child(id))
            }
        }
    },

    methods: {
        handleSave(data) {
            this.$firebaseRefs.survey.update(data)
        },

        async handleChange({ value }, toggle) {
            let permission
            let hasPermission

            const data = {}

            if (value) {
                switch (toggle) {
                    case 'logicEnabled':
                        permission = 'create-logic-flow'
                        hasPermission = this.permissions[permission]

                        break
                    default:
                        hasPermission = true
                }

                if (!hasPermission) {
                    this.track('open-upgrade-modal', permission)

                    const isConfirmed = await this.$refs[`upgrade-${permission}`].open()

                    this.$refs[`upgrade-${permission}`].close()

                    if (isConfirmed)
                        window.location.href = `https://www.surveylegend.com/upgrade?source=upgrade_modal&type=${permission}&level=${this.permissions.level}`

                    return
                } else if (permission) {
                    this.track('has-permission', permission)
                }
            }

            data[toggle] = value

            this.handleSave(data)
        },

        random(max) {
            return Math.random() * (max - 0) + 0
        },

        handleRankEnter() {
            const confettiRef = this.$refs.confetti
            const fragment = document.createDocumentFragment()

            for (let i = 0; i < 16; i++) {
                const styles = `transform: translate3d(${this.random(60) - 35}px, ${
                    this.random(30) - 25
                }px, 0) rotate(${this.random(360)}deg); background: hsla(${this.random(
                    360
                )}, 100%, 50%, 1); animation: confetti 700ms ease-out 900ms forwards; opacity: 0`

                const element = document.createElement('i')

                element.style.cssText = styles
                fragment.appendChild(element)
            }

            confettiRef.appendChild(fragment)
            confettiRef.onanimationend = () => {
                confettiRef.querySelectorAll('i').forEach((child) => child.remove())
            }
        },

        handleRankLeave() {
            const confettiRef = this.$refs.confetti

            confettiRef.querySelectorAll('i').forEach((child) => child.remove())
        },

        handlePreview() {
            this.$router.push({ path: 'preview' })
        },

        track(action, name) {
            this.$gtag.event(action, {
                event_category: name,
                event_label: this.permissions.level
            })
        }
    }
}
</script>

<style scope lang="scss">
.create-view {
    display: flex;
    justify-content: center;

    height: auto;

    min-height: 100vh;
    max-height: 100vh;

    overflow-y: auto;
}

.create-view__aside {
    position: relative;

    height: 100%;

    padding: 16px 16px 32px;

    overflow-y: scroll;

    &:after {
        content: '';

        position: fixed;

        left: 0;
        bottom: 56px;

        width: 21%;
        height: 32px;

        background-image: linear-gradient(to top, rgba(#000, 0.2), rgba(#000, 0));

        pointer-events: none;
    }

    > .row-component {
        color: #2c3e50;
        font-size: 14px;

        margin-bottom: 10px;
    }
}

.create-view__preview {
    position: absolute;

    top: 16px;
    right: 16px;

    .button-component {
        color: #34495e;

        padding: 12px;

        background-color: rgba(#fff, 0.85);

        &:hover {
            background-color: #fff;
        }
    }

    .popover-component__container {
        display: flex;

        justify-content: center;

        padding: 10px 12px;
    }
}

.create-view__questions {
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    list-style-type: none;

    margin-bottom: 24px;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.create-view__group {
    display: flex;
    align-items: center;

    color: #65717e;

    font-size: 14px;
    white-space: nowrap;

    margin-bottom: 12px;

    span:last-child {
        margin-left: 6px;
    }
}

.create-view__question {
    .button-component {
        justify-content: flex-start;

        position: relative;

        color: #34495e;
        font-weight: 400;
        text-align: left;

        width: 100%;

        padding: 11px 14px 11px 12px;

        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) !important;

        &:hover {
            background-color: #fff !important;
            box-shadow: 0 1px 12px rgba(0, 0, 0, 0.15) !important;
        }

        svg {
            margin: -1px 8px 0 0;
        }
    }
}

.create-view__question--disc {
    &:hover circle:last-child {
        animation: select 1s ease forwards;

        @keyframes select {
            50% {
                r: 2;

                opacity: 0;
            }
        }
    }
}

.create-view__question--check-square {
    &:hover path:first-child {
        stroke-dasharray: 34;

        animation: check 1.5s ease forwards;

        @keyframes check {
            50% {
                stroke-dashoffset: 34;
            }
        }
    }
}

.create-view__question--square-chevron {
    &:hover path:last-child {
        transform-origin: 70% 50%;

        animation: flip-y 1.5s ease forwards;

        @keyframes flip-y {
            50% {
                transform: rotate(180deg);
            }
        }
    }
}

.create-view__question--grid {
    &:hover {
        circle:first-of-type {
            animation: grid-select 1s ease forwards;
        }

        circle:last-of-type {
            animation: grid-select 1s ease 0.4s forwards;
        }

        @keyframes grid-select {
            50% {
                r: 0;

                opacity: 0;
            }
        }
    }
}

.create-view__question--ruler {
    &:hover {
        path:nth-child(3) {
            stroke-dasharray: 15;

            animation: scale-y 1s ease 0.1s forwards;
        }

        path:nth-child(4) {
            stroke-dasharray: 15;

            animation: scale-y 1s ease 0.2s forwards;
        }

        path:nth-child(5) {
            stroke-dasharray: 15;

            animation: scale-y 1s ease 0.3s forwards;
        }

        @keyframes scale-y {
            50% {
                stroke-dashoffset: 15;
            }
        }
    }
}

.create-view__question--dashboard {
    &:hover g {
        transform-origin: 50% 50%;

        animation: rotate 1.5s ease forwards;

        @keyframes rotate {
            50% {
                transform: rotate(-110deg);
            }
        }
    }
}

.create-view__question--star {
    &:hover svg {
        animation: bounce 1.5s ease forwards;

        @keyframes bounce {
            0%,
            100% {
                transform: scale(1);
            }
            25% {
                transform: scale(1.3);
            }
            50% {
                transform: scale(0.9);
            }
            75% {
                transform: scale(1.2);
            }
        }
    }
}

.create-view__question--slider {
    &:hover circle {
        animation: slide 1s ease forwards;

        @keyframes slide {
            50% {
                transform: translate(-8px, 0);
            }
        }
    }
}

.create-view__question--emoji {
    &:hover {
        svg {
            animation: bounce-rotate 1.5s ease forwards;

            @keyframes bounce-rotate {
                0%,
                100% {
                    transform: scale(1);
                }
                25% {
                    transform: scale(1.3) rotate(20deg);
                }
                50% {
                    transform: scale(0.9) rotate(-10deg);
                }
                75% {
                    transform: scale(1.2) rotate(10deg);
                }
            }
        }

        path:nth-child(2),
        path:nth-child(3) {
            transform-origin: 50% 50%;

            animation: blink 0.6s ease forwards;

            @keyframes blink {
                50% {
                    transform: scaleY(0.2);
                }
            }
        }
    }
}

.create-view__question--thumb {
    &:hover path:first-child {
        transform-origin: 50% 50%;

        animation: move 1s ease forwards;

        @keyframes move {
            50% {
                transform: translate(-2.5px, 2.5px) rotate(45deg);
            }
        }
    }
}

.create-view__question--image {
    &:hover {
        path:nth-child(1) {
            animation: sky 1.5s ease forwards;

            @keyframes sky {
                50% {
                    fill: #2c3e50;
                }
            }
        }

        path:nth-child(2) {
            transform-origin: 55% 50%;

            animation: sun 1.5s ease forwards;

            @keyframes sun {
                0% {
                    transform: rotate(0);
                }
                25%,
                35% {
                    fill: #fafafa;
                    stroke: #fafafa;
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }

        path:nth-child(3) {
            animation: ground 1.5s ease forwards;

            @keyframes ground {
                50% {
                    fill: #2980b9;
                }
            }
        }
    }
}

.create-view__question--rank {
    position: relative;

    svg {
        overflow: hidden;
    }

    &:hover {
        g {
            transform-origin: 50% 85%;
        }

        g:nth-child(1) {
            animation: grow 0.91s ease 0.09s forwards;
        }

        g:nth-child(2) {
            animation: grow 0.96s ease 0.04s forwards;
        }

        g:nth-child(3) {
            animation: grow 1s ease forwards;
        }

        @keyframes grow {
            50% {
                transform: scale(1, 0);
            }
        }
    }
}

.create-view__question--rank__confetti {
    i {
        display: block;

        position: absolute;

        top: 12px;
        left: 26px;

        width: 1px;
        height: 2px;

        opacity: 0;
    }

    @keyframes confetti {
        from {
            transform: translate3d(0, 0, 0);

            opacity: 1;
        }
    }
}

.create-view__question--upload-cloud {
    svg {
        overflow: visible;
    }

    &:hover g {
        transform-origin: 50% 50%;

        animation: up 1s ease forwards;

        @keyframes up {
            50% {
                transform: translateY(6px);
            }
        }
    }
}

.create-view__question--text {
    &:hover path {
        stroke-dasharray: 19.5;

        transform: scale(-1, 1);
        transform-origin: 47% 50%;

        animation: text 1s ease forwards;

        @keyframes text {
            50% {
                stroke-dashoffset: 20;
            }
        }
    }
}

.create-view__question--comment {
    &:hover {
        path:nth-child(2) {
            stroke-dasharray: 14.5;

            transform: scale(-1, 1);
            transform-origin: 50% 50%;

            animation: comment 1s ease forwards;
        }

        path:nth-child(3) {
            stroke-dasharray: 14.5;

            transform: scale(-1, 1);
            transform-origin: 44% 50%;

            animation: comment 1s ease 0.2s forwards;
        }

        path:nth-child(4) {
            stroke-dasharray: 14.5;

            transform: scale(-1, 1);
            transform-origin: 44% 50%;

            animation: comment 1s ease 0.4s forwards;
        }

        @keyframes comment {
            50% {
                stroke-dashoffset: 15;
            }
        }
    }
}

.create-view__question--multiple-text {
    &:hover {
        path:nth-child(3) {
            stroke-dasharray: 14.5;

            transform: scale(-1, 1);
            transform-origin: 38% 50%;

            animation: multiple-text 1s ease forwards;
        }

        path:nth-child(4) {
            stroke-dasharray: 14.5;

            transform: scale(-1, 1);
            transform-origin: 45% 50%;

            animation: multiple-text 1s ease 0.2s forwards;
        }

        @keyframes multiple-text {
            50% {
                stroke-dashoffset: 15;
            }
        }
    }
}

.create-view__question--profile {
    &:hover {
        path:nth-child(4) {
            stroke-dasharray: 6.5;

            transform: scale(-1, 1);
            transform-origin: 70% 50%;

            animation: profile-text 1s ease forwards;
        }

        path:nth-child(5) {
            stroke-dasharray: 6.5;

            transform: scale(-1, 1);
            transform-origin: 73% 50%;

            animation: profile-text 1s ease 0.2s forwards;
        }

        path:nth-child(6) {
            stroke-dasharray: 6.5;

            transform: scale(-1, 1);
            transform-origin: 73% 50%;

            animation: profile-text 1s ease 0.4s forwards;
        }

        @keyframes profile-text {
            50% {
                stroke-dashoffset: 7;
            }
        }
    }
}

.create-view__question--hash {
    &:hover {
        line {
            stroke-dasharray: 20;

            animation: draw 2s ease forwards;
        }

        @keyframes draw {
            50% {
                stroke-dashoffset: 20;
            }
        }
    }
}

.create-view__question--at-sign {
    &:hover {
        circle {
            stroke-dasharray: 22.5;

            animation: draw-circle 2s ease forwards;
        }

        path {
            stroke-dasharray: 70.5;

            animation: draw-path 2s ease forwards;
        }

        @keyframes draw-circle {
            50% {
                stroke-dashoffset: 23;
            }
        }

        @keyframes draw-path {
            50% {
                stroke-dashoffset: 70;
            }
        }
    }
}

.create-view__question--page-break {
    &:hover {
        rect:first-of-type,
        g:first-of-type {
            animation: move-down 1.5s ease forwards;
        }

        rect:last-of-type,
        g:last-of-type {
            animation: move-up 1.5s ease forwards;
        }

        svg > path:nth-child(5) {
            animation: fade-left 1.5s ease forwards;
        }

        svg > path:nth-child(6) {
            animation: fade-right 1.5s ease forwards;
        }

        @keyframes move-down {
            50% {
                transform: translateY(5.75px);
            }
        }

        @keyframes move-up {
            50% {
                transform: translateY(-5.75px);
            }
        }

        @keyframes fade-left {
            50% {
                transform: translateX(-4px);

                opacity: 0;
            }
        }

        @keyframes fade-right {
            50% {
                transform: translateX(4px);

                opacity: 0;
            }
        }
    }
}

.create-view__question--bookmark {
    path:last-child {
        transform-origin: 50% 2.5px;
    }

    &:hover {
        path {
            animation: scale-out 1s ease forwards;
        }

        @keyframes scale-out {
            50% {
                transform: scaleY(0.15);
            }
        }
    }
}

.create-view__question--message-circle {
    &:hover {
        svg {
            transform-origin: 100% 100%;

            animation: bubble 1s ease-out forwards;

            @keyframes bubble {
                50% {
                    transform: scale(0) rotate(-20deg);

                    opacity: 0;
                }
            }
        }
    }
}

.create-view__question--message-circle-reversed {
    @extend .create-view__question--message-circle;

    &:hover {
        svg {
            transform-origin: 0% 100%;
        }
    }
}

.create-view__question--badge-check {
    svg {
        overflow: visible;
    }

    &:hover {
        svg {
            animation: scale 1s ease forwards;

            @keyframes scale {
                50% {
                    transform: scale(1.2);
                }
            }
        }

        path:last-child {
            transform-origin: 50% 50%;

            animation: glow 1s ease forwards;

            @keyframes glow {
                50% {
                    transform: rotate(90deg);

                    filter: drop-shadow(0 0 2px rgba(255, 153, 0, 0.75));
                }
            }
        }
    }
}

.create-view__question--tag {
    &:hover {
        svg {
            animation: bounce 1.5s ease forwards;
        }
    }
}

.create-view__participate {
    width: 100%;
    max-width: 800px;
}

.create-view__footer {
    width: 100%;

    color: #fff;
    font-size: 14px;

    padding: 12px 16px;

    background-color: #34495e;

    .help-component__trigger:hover {
        background-color: #94dbfc;
        border-color: #94dbfc;
    }
}

.create-view__icon {
    display: inline-block;

    width: 16px;
    height: 16px;

    margin: 0 8px 0 0;

    background-size: contain;
}

.create-view__icon--logic {
    @include icon('~@/assets/svg/icons/shuffle.svg');
}
</style>
